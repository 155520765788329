import { useContext, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import PrivateRouter from "./extras/PrivateRouter";

import { UserContext } from "./context/UserProvider";
import { loginChange } from "./extras/firebase";
import useDatabase from "./hooks/useDatabase";

import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import AdminComponent from "./pages/Admin";
import ClienteNuevo from "./pages/ClienteNuevo";

import "./App.css";

function App() {
  const { userData, changeUser } = useContext(UserContext);

  const navigate = useNavigate();

  const database = useDatabase();

  useEffect(() => {
    const updateUser = (user) => {
      if (user) {
        changeUser({ type: "LOGIN_USER", payload: user });
      } else changeUser({ type: "LOGOUT_USER" });
      navigate("/");
    };

    loginChange(updateUser);
  }, []);

  return (
    <div>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<p>There's nothing here: 404!</p>} />
        <Route element={<PrivateRouter logged={userData.logged} />}>
          <Route path="/" element={<Dashboard database={database} />} />
          <Route
            path="/admin"
            element={
              <AdminComponent
                database={database}
                titulo="Admin"
                inicial="Nuevos"
                chips={[
                  "Nuevos",
                  "Reciclaje",
                  "Reingreso",
                  // "Adicionales",
                  "Confirmar pago",
                  "Pagos confirmados",

                  "Pagos por revisar",

                  "Asignados",
                  "Seguimiento",
                  "Autorizados",
                  "Contactados",

                  // "Asignados adicionales",
                  // "Seguimiento adicionales",

                  "Espera",
                  "Rechazados",
                  "Todos",
                ]}
                asesores={[
                  "Jaime",
                  "Nancy",
                  "Citla",
                  "Osiel",
                  "Sam",
                  "Betty",
                  "Sandra",
                  "Marisol",
                  "Danna",
                  "Ricardo",
                  "Freddy",
                  "Sonia",
                  "Jesus",
                  "Daniela",
                  "Ernesto",
                  "Blanca",
                  "Lizbeth",
                  "Ana",
                  "Anel",
                  "Diana",
                  "Bryan",
                  "Enrique",
                  "Enrique Reyes",
                  "Jonathan Becerra",
                  "Vania Morales",
                  "Erika Licona",
                  "Alin Yarek",
                  "Vero",
                  "Autos",
                  "Juan Luis",
                  "Admin",
                  "Soporte",
                  "Todos",
                ]}
                bases={true}
                marcas={true}
              />
            }
          />
          <Route
            path="/coordinacion"
            element={
              <AdminComponent
                database={database}
                titulo="Admin"
                inicial="Confirmar pago"
                chips={[
                  // "Adicionales",
                  "Confirmar pago",
                  "Pagos confirmados",

                  "Pagos por revisar",

                  "Asignados",
                  "Seguimiento",
                  "Autorizados",
                  "Contactados",

                  // "Asignados adicionales",
                  // "Seguimiento adicionales",

                  "Espera",
                  "Rechazados",
                  "Todos",
                ]}
                asesores={[
                  "Jaime",
                  "Nancy",
                  "Citla",
                  "Osiel",
                  "Sam",
                  "Betty",
                  "Sandra",
                  "Marisol",
                  "Danna",
                  "Ricardo",
                  "Freddy",
                  "Sonia",
                  "Jesus",
                  "Daniela",
                  "Ernesto",
                  "Blanca",
                  "Lizbeth",
                  "Ana",
                  "Anel",
                  "Diana",
                  "Bryan",
                  "Enrique",
                  "Enrique Reyes",
                  "Jonathan Becerra",
                  "Vania Morales",
                  "Erika Licona",
                  "Alin Yarek",
                  "Vero",
                  "Autos",
                  "Todos",
                ]}
                bases={true}
                marcas={true}
              />
            }
          />
          <Route
            path="/pilares"
            element={
              <AdminComponent
                database={database}
                inicial="Asignados"
                titulo="Coordinación"
                chips={[
                  "Reingreso",
                  "Asignados",
                  "Contactados",
                  "Seguimiento",
                  "Autorizados",
                  "Pagos confirmados",

                  // "Asignados adicionales",
                  // "Seguimiento adicionales",

                  "Espera",
                  "Rechazados",
                  "Todos",
                ]}
                asesores={["Citla", "Marisol", "Daniela", "Ana", "Todos"]}
                bases={true}
                marcas={false}
              />
            }
          />

          <Route
            path="/omega"
            element={
              <AdminComponent
                database={database}
                inicial="Asignados"
                titulo="Coordinación"
                chips={[
                  "Reingreso",
                  "Asignados",
                  "Contactados",
                  "Seguimiento",
                  "Autorizados",
                  "Pagos confirmados",

                  // "Asignados adicionales",
                  // "Seguimiento adicionales",

                  "Espera",
                  "Rechazados",
                  "Todos",
                ]}
                asesores={[
                  "Sam",
                  "Osiel",
                  "Diana",
                  "Bryan",
                  "Enrique",
                  "Todos",
                ]}
                bases={true}
                marcas={false}
              />
            }
          />

          <Route
            path="/capacitacion"
            element={
              <AdminComponent
                database={database}
                inicial="Asignados"
                titulo="Capacitación"
                chips={[
                  "Reingreso",
                  "Asignados",
                  "Contactados",
                  "Seguimiento",
                  "Autorizados",
                  "Pagos confirmados",

                  // "Asignados adicionales",
                  // "Seguimiento adicionales",

                  "Espera",
                  "Rechazados",
                  "Todos",
                ]}
                asesores={[
                  "Enrique Reyes",
                  "Vania Morales",
                  "Erika Licona",
                  "Alin Yarek",
                  "Danna",
                  "Sonia",
                  "Lizbeth",
                  "Sandra",
                  "Todos",
                ]}
                bases={true}
                marcas={false}
              />
            }
          />
          <Route
            path="/asignacion"
            element={
              <AdminComponent
                database={database}
                inicial="Nuevos"
                titulo="Asign"
                chips={[
                  "Nuevos",

                  "Reingreso",

                  // "Asignados adicionales",
                  // "Seguimiento adicionales",

                  "Todos",
                ]}
                asesores={[]}
                bases={false}
                marcas={true}
              />
            }
          />

          <Route path="/add" element={<ClienteNuevo database={database} />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
