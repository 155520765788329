import { Fragment, useEffect, useState } from "react";
import styles from "./ClientCard.module.scss";

const ClientCard = ({
  onClientClick,
  details,
  admin,
  activeId,
  showDetails,
  indice,
}) => {
  const status = details.estado;

  const [tiempo, setTiempo] = useState();

  const timestamp = new Date(Date.now());

  const [date] = details.fecha_actualizacion.replace(/,/g, "").split(" ");
  const [day, month, year] = date.split("/");

  const fecha = new Date(year, month - 1, day);

  const dayPassed = ((timestamp - fecha) / (1000 * 3600 * 24)).toFixed(0);

  useEffect(() => {
    switch (dayPassed) {
      case "-0":
        setTiempo(styles.uno);
        break;
      case "0":
        setTiempo(styles.uno);
        break;
      case "1":
        setTiempo(styles.uno);
        break;
      case "2":
        status === 1 ? setTiempo(styles.dos) : setTiempo(styles.uno);
        break;
      case "3":
        status === 1 ? setTiempo(styles.mas) : setTiempo(styles.uno);
        break;
      case "4":
        status === 1 ? setTiempo(styles.mas) : setTiempo(styles.dos);
        break;
      case "5":
        status === 1 ? setTiempo(styles.mas) : setTiempo(styles.dos);
        break;
      case "6":
        status === 1 ? setTiempo(styles.mas) : setTiempo(styles.dos);
        break;
      case "7":
        status === 1 ? setTiempo(styles.mas) : setTiempo(styles.dos);
        break;
      case "8":
        status === 1 ? setTiempo(styles.mas) : setTiempo(styles.dos);
        break;
      case "9":
        status === 1 ? setTiempo(styles.mas) : setTiempo(styles.dos);
        break;
      case "10":
        setTiempo(styles.mas);
        break;

      default:
        setTiempo(styles.mas);
    }
  }, [dayPassed, status]);

  const SinAsignar = () => {
    var solicitud;

    if (details.solicitud === null) {
      solicitud = {
        fecha: details.fecha_actualizacion,
        marca: details.viabilidad[details.viabilidad.length - 1].marca,
        nombre:
          details.viabilidad[details.viabilidad.length - 1].nombre_completo,
        correo: details.viabilidad[details.viabilidad.length - 1].correo,
        telefono: details.viabilidad[details.viabilidad.length - 1].numero,
        horario: "No hay horario",
        mensaje: "",
        monto: details.viabilidad[details.viabilidad.length - 1].motivo,
      };
    } else {
      solicitud = details.solicitud[details.solicitud.length - 1];
    }

    return (
      <div
        className={` ${styles.clienteCard} ${tiempo} ${
          details._id === activeId && showDetails ? styles.seleccionado : ""
        }`}
        onClick={() => {
          onClientClick(details._id, indice);
        }}
      >
        <div className={styles.clienteCardCabecera}>
          <h1 style={{ textAlign: "left" }}>
            {solicitud.fecha}
            <br />

            <span
              style={{
                color: !solicitud.marca
                  ? "red"
                  : solicitud.marca === "Prestamo 24"
                  ? "red"
                  : solicitud.marca === "Bien Crediticio"
                  ? "#0F769C"
                  : "#BEA313",
                textAlign: "left",
              }}
            >
              {!solicitud.marca ? "Prestamo 24" : solicitud.marca}
            </span>
          </h1>
          {details.estudio === 1 ? (
            <span className="material-symbols-outlined">description</span>
          ) : (
            <></>
          )}
        </div>

        <h1>{solicitud.nombre}</h1>
        <p>{solicitud.correo}</p>
        <p>{solicitud.telefono}</p>
        <p>{solicitud.horario}</p>
        <br></br>
        <b>{solicitud.monto}</b>

        {solicitud.monto === "Menos de $49,000" ? (
          <p>{solicitud.mensaje}</p>
        ) : (
          <></>
        )}
      </div>
    );
  };

  const Asignado = ({ monto }) => {
    const solicitud = details.solicitud[details.solicitud.length - 1];

    return (
      <div
        className={` ${styles.clienteCard} ${tiempo} ${
          details._id === activeId && showDetails ? styles.seleccionado : ""
        }`}
        onClick={() => {
          onClientClick(details._id);
        }}
      >
        <div className={styles.clienteCardCabecera}>
          <h1 style={{ textAlign: "left" }}>
            Sin Contactar
            <br />
            <span
              style={{
                color: !solicitud.marca
                  ? "red"
                  : solicitud.marca === "Prestamo 24"
                  ? "red"
                  : solicitud.marca === "Bien Crediticio"
                  ? "#0F769C"
                  : "#BEA313",
                textAlign: "left",
              }}
            >
              {!solicitud.marca ? "Prestamo 24" : solicitud.marca}
            </span>
            {details.estudio === 1 ? (
              <Fragment>
                <br />
                <span className="material-symbols-outlined">description</span>
              </Fragment>
            ) : (
              <></>
            )}
          </h1>
          <h1>{monto}</h1>
        </div>

        <h1>{details.nombre}</h1>
        <p>{details.solicitud[0].mensaje}</p>
        <br></br>
        <p style={{ textAlign: "end" }}>
          {admin ? (
            <Fragment>
              {details.asesor_activo_nombre} <br />
            </Fragment>
          ) : (
            ""
          )}
          Actualizado: {details.fecha_actualizacion}
        </p>
      </div>
    );
  };

  const Reingreso = ({ monto, literal }) => {
    const solicitud = details.solicitud[details.solicitud.length - 1];

    return (
      <div
        className={` ${styles.clienteCard} ${tiempo} ${
          details._id === activeId && showDetails ? styles.seleccionado : ""
        }`}
        onClick={() => {
          onClientClick(details._id);
        }}
      >
        <div className={styles.clienteCardCabecera}>
          <h1 style={{ textAlign: "left" }}>
            Reingreso
            <br />
            <span
              style={{
                color: !solicitud.marca
                  ? "red"
                  : solicitud.marca === "Prestamo 24"
                  ? "red"
                  : solicitud.marca === "Bien Crediticio"
                  ? "#0F769C"
                  : "#BEA313",
                textAlign: "left",
              }}
            >
              {!solicitud.marca ? "Prestamo 24" : solicitud.marca}
            </span>
            <br />
            <div style={{ display: "flex", alignItems: "center" }}>
              {details.estudio === 1 ? (
                <span className="material-symbols-outlined">description</span>
              ) : (
                <></>
              )}
              <span className="material-symbols-outlined">cycle</span>
              {literal}
            </div>
          </h1>
          <h1>{monto}</h1>
        </div>

        <h1>{details.nombre}</h1>
        <p>{details.solicitud[0].mensaje}</p>
        <br></br>
        <p style={{ textAlign: "end" }}>
          {admin ? (
            <Fragment>
              {details.asesor_activo_nombre} <br />
            </Fragment>
          ) : (
            ""
          )}
          Actualizado: {details.fecha_actualizacion}
        </p>
      </div>
    );
  };

  const Contactado = ({ monto }) => {
    const solicitud = details.solicitud[details.solicitud.length - 1];

    return (
      <div
        className={` ${styles.clienteCard} ${tiempo} ${
          details._id === activeId && showDetails ? styles.seleccionado : ""
        }`}
        onClick={() => {
          onClientClick(details._id);
        }}
      >
        <div className={styles.clienteCardCabecera}>
          <h1 style={{ textAlign: "left" }}>
            Contactado
            <br />
            <span
              style={{
                color: !solicitud.marca
                  ? "red"
                  : solicitud.marca === "Prestamo 24"
                  ? "red"
                  : solicitud.marca === "Bien Crediticio"
                  ? "#0F769C"
                  : "#BEA313",
                textAlign: "left",
              }}
            >
              {!solicitud.marca ? "Prestamo 24" : solicitud.marca}
            </span>
            {details.estudio === 1 ? (
              <Fragment>
                <br />
                <span className="material-symbols-outlined">description</span>
              </Fragment>
            ) : (
              <></>
            )}
          </h1>
          <h1>${monto}</h1>
        </div>

        <h1>{details.nombre}</h1>

        <br></br>
        <p style={{ textAlign: "end" }}>
          {admin ? (
            <Fragment>
              {details.asesor_activo_nombre} <br />
            </Fragment>
          ) : (
            ""
          )}
          Actualizado: {details.fecha_actualizacion}
        </p>
      </div>
    );
  };

  const Seguimiento = ({ title, monto }) => {
    const solicitud = details.solicitud[details.solicitud.length - 1];

    return (
      <div
        className={` ${styles.clienteCard} ${tiempo} ${
          details._id === activeId && showDetails ? styles.seleccionado : ""
        }`}
        onClick={() => {
          onClientClick(details._id);
        }}
      >
        <div className={styles.clienteCardCabecera}>
          <h1 style={{ textAlign: "left" }}>
            {title}
            <br />
            <span
              style={{
                color: !solicitud.marca
                  ? "red"
                  : solicitud.marca === "Prestamo 24"
                  ? "red"
                  : solicitud.marca === "Bien Crediticio"
                  ? "#0F769C"
                  : "#BEA313",
                textAlign: "left",
              }}
            >
              {!solicitud.marca ? "Prestamo 24" : solicitud.marca}
            </span>

            {details.estudio === 1 ? (
              <Fragment>
                <br />
                <span className="material-symbols-outlined">description</span>
              </Fragment>
            ) : (
              <></>
            )}
          </h1>

          <h1 style={{ textAlign: "end" }}>$ {monto}</h1>
        </div>

        <h1>{details.nombre}</h1>

        <p style={{ textAlign: "end", marginTop: "1rem" }}>
          {admin ? (
            <span>
              {details.asesor_activo_nombre} <br />
            </span>
          ) : (
            ""
          )}
          Actualizado: {details.fecha_actualizacion}
        </p>
      </div>
    );
  };

  const ConfirmarPago = ({ monto, concepto }) => {
    return (
      <div
        className={` ${styles.clienteCard} ${tiempo} ${
          details._id === activeId && showDetails ? styles.seleccionado : ""
        }`}
        onClick={() => {
          onClientClick(details._id);
        }}
      >
        <div className={styles.clienteCardCabecera}>
          <p>
            <b>{concepto}</b>
          </p>

          <h1 style={{ textAlign: "end" }}>${monto}</h1>
        </div>

        <p>{details.nombre}</p>
        <p style={{ textAlign: "end", marginTop: "1rem" }}>
          {admin ? (
            <span>
              {details.asesor_activo_nombre} <br />
            </span>
          ) : (
            ""
          )}
          Actualizado: {details.fecha_actualizacion}
        </p>
      </div>
    );
  };

  const AsignarAdicional = ({ title }) => {
    return (
      <div
        className={` ${styles.clienteCard} ${tiempo} ${
          details._id === activeId && showDetails ? styles.seleccionado : ""
        }`}
        onClick={() => {
          onClientClick(details._id);
        }}
      >
        <div className={styles.clienteCardCabecera}>
          <p>{title}</p>
          <h1 style={{ textAlign: "end" }}>PR-1016</h1>
        </div>
        <h1>{details.nombre}</h1>
        <br></br>

        <table style={{ textAlign: "center" }}>
          <tr>
            <th>#</th>
            <th>Monto</th>
            <th>Asesor</th>
          </tr>

          {details.pago.map((item, key) => (
            <tr>
              <td>{key}</td>
              <td>$ {item.monto}</td>
              <td>{item.asesor_nombre}</td>
            </tr>
          ))}
        </table>

        <br></br>
        <p style={{ textAlign: "end" }}>
          Actualizado: {fecha.toLocaleDateString()}
        </p>
      </div>
    );
  };

  const SinContactoAdicional = () => {};

  const SeguimientoAdicional = () => {};

  const SinConcluir = ({ title, monto }) => {
    return (
      <div
        className={` ${styles.clienteCard} ${
          details._id === activeId && showDetails ? styles.seleccionado : ""
        }`}
        onClick={() => {
          onClientClick(details._id);
        }}
      >
        <div className={styles.clienteCardCabecera}>
          <h1 style={{ textAlign: "left" }}>
            Cancelado <br /> {title}
          </h1>
          <h1>{monto}</h1>
        </div>

        <h1>{details.nombre}</h1>
        <span>
          <h1>{!details.notas ? <></> : details.notas[0].monto}</h1>
        </span>
        <br></br>
        <p style={{ textAlign: "end" }}>
          {admin ? (
            <Fragment>
              {details.asesor_activo_nombre} <br />
            </Fragment>
          ) : (
            ""
          )}
          Actualizado: {details.fecha_actualizacion}
        </p>
      </div>
    );
  };

  const Espera = ({ title, monto }) => {
    const solicitud = details.solicitud[details.solicitud.length - 1];

    return (
      <div
        className={` ${styles.clienteCard} ${
          details._id === activeId && showDetails ? styles.seleccionado : ""
        }`}
        onClick={() => {
          onClientClick(details._id);
        }}
      >
        <div className={styles.clienteCardCabecera}>
          <h1 style={{ textAlign: "left" }}>
            {title}
            <br />
            <span
              style={{
                color: !solicitud.marca
                  ? "red"
                  : solicitud.marca === "Prestamo 24"
                  ? "red"
                  : solicitud.marca === "Bien Crediticio"
                  ? "#0F769C"
                  : "#BEA313",
                textAlign: "left",
              }}
            >
              {!solicitud.marca ? "Prestamo 24" : solicitud.marca}
            </span>
          </h1>

          <h1 style={{ textAlign: "end" }}>$ {monto}</h1>
        </div>

        <h1>{details.nombre}</h1>

        <p style={{ textAlign: "end", marginTop: "1rem" }}>
          {admin ? (
            <span>
              {details.asesor_activo_nombre} <br />
            </span>
          ) : (
            ""
          )}
          Actualizado: {details.fecha_actualizacion}
        </p>
      </div>
    );
  };

  // TODO: Actualizar codigos
  return {
    // 0 -- SIN ASIGNAR
    0: (
      <SinAsignar
        monto={
          !details.solicitud
            ? ""
            : details.solicitud[details.solicitud.length - 1].monto
        }
      />
    ),
    // 1 -- ASIGNADO NUEVO, SIN CONTACTAR
    1:
      details.nueva_solicitud !== 2 ? (
        <Asignado
          monto={
            !details.solicitud
              ? ""
              : details.solicitud[details.solicitud.length - 1].monto
          }
        />
      ) : (
        <Reingreso
          literal="Nuevo"
          monto={
            !details.solicitud
              ? ""
              : details.solicitud[details.solicitud.length - 1].monto
          }
        />
      ),
    // 2 -- CONTACTADO NUEVO, SIN COTIZAR
    2:
      details.nueva_solicitud !== 2 ? (
        <Contactado
          monto={
            !details.solicitud
              ? ""
              : details.solicitud[details.solicitud.length - 1].monto
          }
        />
      ) : (
        <Reingreso
          literal="Contactado"
          monto={
            !details.solicitud
              ? ""
              : details.solicitud[details.solicitud.length - 1].monto
          }
        />
      ),
    // 3 -- SEGUIMIENTO NUEVO, SIN AUTORIZAR
    3:
      details.nueva_solicitud !== 2 ? (
        <Seguimiento
          title="En seguimiento"
          monto={
            !details.cotizaciones
              ? ""
              : details.cotizaciones[details.cotizaciones.length - 1].monto
          }
        />
      ) : (
        <Reingreso
          literal="Seguimiento"
          monto={
            !details.solicitud
              ? ""
              : details.solicitud[details.solicitud.length - 1].monto
          }
        />
      ),
    // 3 -- AUTORIZADO, SIN PAGO
    4:
      details.nueva_solicitud !== 2 ? (
        <Seguimiento
          title="Autorizado"
          monto={
            !details.aprobaciones
              ? ""
              : details.aprobaciones[details.aprobaciones.length - 1].monto
          }
        />
      ) : (
        <Reingreso
          literal="Autorizado"
          monto={
            !details.solicitud
              ? ""
              : details.solicitud[details.solicitud.length - 1].monto
          }
        />
      ),
    // 4 -- PAGO REGISTRADO
    5: (
      <ConfirmarPago
        monto={
          !details.fichas ? "" : details.fichas[details.fichas.length - 1].monto
        }
        concepto={
          !details.fichas
            ? "Garantia"
            : details.fichas[details.fichas.length - 1].concepto
        }
      />
    ),
    // 5 -- PAGO CONFIRMADO
    7:
      details.nueva_solicitud !== 2 ? (
        <Seguimiento
          title="Cerrado"
          monto={
            !details.fichas
              ? ""
              : details.fichas[details.fichas.length - 1].monto
          }
        />
      ) : (
        <Reingreso
          literal="Cerrado"
          monto={
            !details.solicitud
              ? ""
              : details.solicitud[details.solicitud.length - 1].monto
          }
        />
      ),
    9:
      details.nueva_solicitud !== 2 ? (
        <Seguimiento
          title="Por revisión"
          monto={
            !details.fichas
              ? ""
              : details.fichas[details.fichas.length - 1].monto
          }
        />
      ) : (
        <Reingreso
          literal="Cerrado"
          monto={
            !details.solicitud
              ? ""
              : details.solicitud[details.solicitud.length - 1].monto
          }
        />
      ),
    // 8 -- ASIGNAR ADICIONAL
    8:
      details.nueva_solicitud !== 2 ? (
        <AsignarAdicional title="Regresar contrato" />
      ) : (
        <Reingreso
          literal="Adicional"
          monto={
            !details.solicitud
              ? ""
              : details.solicitud[details.solicitud.length - 1].monto
          }
        />
      ),

    // 9 -- PAGO CONFIRMADO
    9:
      details.nueva_solicitud !== 2 ? (
        <SinContactoAdicional />
      ) : (
        <Reingreso
          literal="Cerrado"
          monto={
            !details.solicitud
              ? ""
              : details.solicitud[details.solicitud.length - 1].monto
          }
        />
      ),

    // 10 -- ASIGNADO ADICIONAL
    10:
      details.nueva_solicitud !== 2 ? (
        <SeguimientoAdicional />
      ) : (
        <Reingreso
          literal="Adicional"
          monto={
            !details.solicitud
              ? ""
              : details.solicitud[details.solicitud.length - 1].monto
          }
        />
      ),

    // 50 -- Espera vieja
    50:
      details.nueva_solicitud !== 2 ? (
        <SinConcluir />
      ) : (
        <Reingreso
          literal="Espera"
          monto={
            !details.solicitud
              ? ""
              : details.solicitud[details.solicitud.length - 1].monto
          }
        />
      ),
    // 60 -- Nueva Espera
    60:
      details.nueva_solicitud !== 2 ? (
        <Espera
          title="En espera"
          monto={
            !details.aprobaciones
              ? ""
              : details.aprobaciones[details.aprobaciones.length - 1].monto
          }
        />
      ) : (
        <Reingreso
          literal="Espera"
          monto={
            !details.solicitud
              ? ""
              : details.solicitud[details.solicitud.length - 1].monto
          }
        />
      ),

    // 540 -- SIN SOLICITUD
    540: <>SIN DATOS</>,

    // 99 -- GASTOS MEDICOS
    99: <></>,

    // 100 -- NO LE INTERESA
    100:
      details.nueva_solicitud !== 2 ? (
        <SinConcluir
          title={"No se contactó"}
          monto={
            !details.solicitud
              ? ""
              : details.solicitud[details.solicitud.length - 1].monto
          }
        />
      ) : (
        <Reingreso
          literal="No se contactó"
          monto={
            !details.solicitud
              ? ""
              : details.solicitud[details.solicitud.length - 1].monto
          }
        />
      ),

    // 101 -- NO SE PUEDE CONTACTAR
    101:
      details.nueva_solicitud !== 2 ? (
        <SinConcluir
          title={"No le interesa "}
          monto={
            !details.solicitud
              ? ""
              : details.solicitud[details.solicitud.length - 1].monto
          }
        />
      ) : (
        <Reingreso
          literal="No le interesa"
          monto={
            !details.solicitud
              ? ""
              : details.solicitud[details.solicitud.length - 1].monto
          }
        />
      ),

    // 102 -- NO INDICA CANTIDAD
    102:
      details.nueva_solicitud !== 2 ? (
        <SinConcluir
          title={"No indica cantidad"}
          monto={
            !details.solicitud
              ? ""
              : details.solicitud[details.solicitud.length - 1].monto
          }
        />
      ) : (
        <Reingreso
          literal="No indica cantidad"
          monto={
            !details.solicitud
              ? ""
              : details.solicitud[details.solicitud.length - 1].monto
          }
        />
      ),

    // 103 -- NO ENVIA DOCUMENTOS
    103:
      details.nueva_solicitud !== 2 ? (
        <SinConcluir
          title={"No indica cantidad"}
          monto={
            !details.cotizaciones
              ? ""
              : details.cotizaciones[details.cotizaciones.length - 1].monto
          }
        />
      ) : (
        <Reingreso
          literal="No indica cantidad"
          monto={
            !details.solicitud
              ? ""
              : details.solicitud[details.solicitud.length - 1].monto
          }
        />
      ),

    // 104 -- NO QUIERE PAGAR
    104:
      details.nueva_solicitud !== 2 ? (
        <SinConcluir
          title={"No quiere pagar"}
          monto={
            !details.solicitud
              ? ""
              : details.solicitud[details.solicitud.length - 1].monto
          }
        />
      ) : (
        <Reingreso
          literal="No quiere pagar"
          monto={
            !details.solicitud
              ? ""
              : details.solicitud[details.solicitud.length - 1].monto
          }
        />
      ),

    // 106 -- FICHA INVALIDA
    105:
      details.nueva_solicitud !== 2 ? (
        <SinConcluir />
      ) : (
        <Reingreso
          literal="No quiere pagar"
          monto={
            !details.solicitud
              ? ""
              : details.solicitud[details.solicitud.length - 1].monto
          }
        />
      ),
    // 107 -- NO ENVIA CONTRATO
    107: <SinConcluir />,

    null: <>Sin datos</>,
  }[status];
};

export default ClientCard;
