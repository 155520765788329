/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import ChipContainerComponent from "../../components/ChipContainer";
import ClientDetails from "../../components/ClientDetails";
import ClientsContainer from "../../components/ClientsContainer";
import SearchBar from "../../components/SearchBar";

import styles from "./AdminComponent.module.scss";
import { UserContext } from "../../context/UserProvider";
import SideMenuComponent from "../../components/SideMenuComponent/SideMenuComponent";

const AdminComponent = ({
  database,
  titulo,
  chips,
  asesores,
  inicial,
  bases,
  marcas,
}) => {
  const [chip, setChip] = useState(inicial);
  const [baseChip, setBaseChip] = useState(bases ? "Nueva" : "Todo");
  const [marcaChip, setMarcaChip] = useState("Todas");
  const [asesorChip, setAsesorChip] = useState("Todos");

  const [textFilter, setTextFilter] = useState("");

  const [clientes, setClientes] = useState([]);
  const [clientesFiltrados, setClientesFiltrados] = useState([]);

  const [showDetails, setShowDetails] = useState(false);
  const [activeDetails, setActiveDetails] = useState({ solicitud: [{}] });
  const [activeId, setActiveId] = useState("");
  const [activeClient, setActiveClient] = useState(1);
  const [showSidemenu, setShowSidemenu] = useState(false);

  const { userData } = useContext(UserContext);

  const updateClientes = () => {
    console.log("Actualizando clientes");
    setClientes(database.getAdminList(chip, baseChip, asesorChip, marcaChip));
  };

  // Actualiza los clientes cuando cambian los filtros
  useEffect(() => {
    if (!database.loading) {
      updateClientes();
    }
  }, [chip, baseChip, asesorChip, marcaChip, database]);

  // Funcion de busqueda
  useEffect(() => {
    if (!!textFilter) {
      setClientesFiltrados(
        clientes.filter((item) => {
          return item.telefono
            .toUpperCase()
            .includes(textFilter.toUpperCase()) ||
            item.correo.toUpperCase().includes(textFilter.toUpperCase()) ||
            !item.nombre
            ? true
            : item.nombre.toUpperCase().includes(textFilter.toUpperCase());
        })
      );
    } else setClientesFiltrados(clientes);
  }, [textFilter, clientes]);

  // Funcion click que muestra detalles de cliente
  const onClientClick = (id, key) => {
    setActiveId(id);
    setActiveDetails(database.getDetails(id));
    setShowDetails(true);
    setActiveClient(key);
  };

  // Funcion que da hora actual
  const fechaActual = () => {
    const timestamp = new Date(Date.now());
    return timestamp.toLocaleString("es-MX", {
      timeZone: "America/Mexico_City",
    });
  };

  // Adaptador de llamada para asignar en base de datos
  const asignarCliente = async (id, asesor_nombre, asesor_id, categoria, estudio, marca) => {

    const resultado = await database.asignarGo(
      id,
      asesor_nombre,
      asesor_id,
      fechaActual(),
      categoria,
      estudio,
      marca

    );

    if (resultado.status) {
      //  Recarga la base
      //  Oculta los detalles
    } else {
      //  Muestra aviso de error
      console.log(resultado.error);
      alert(resultado.error);
    }

    setActiveDetails({ solicitud: [{}] });
    updateClientes();
    setShowDetails(false);

    return false;
  };

  const rechazar = async (id) => {
    const result = await database.rechazarCliente(id, fechaActual());

    if (result) {
      //  Recarga la base
      updateClientes();
      //  Oculta los detalles
    } else {
      //  Muestra aviso de error
      console.log("Error al actualizar datos");
    }
  };

  const retirar = async (id) => {
    const result = await database.retirar(id, fechaActual());

    result ? updateClientes() : console.log("Error al actualizar");

    setShowDetails(false);
  };

  const agregarNota = async (id, nota) => {
    const resultado = await database.actualizaGo({
      tipo: 33,
      cliente: id,
      id: userData.data.uid,
      data: {
        texto: nota,
        fecha: fechaActual(),
        fecha_proxima: fechaActual(),
      },
    });

    if (resultado) {
      //  Recarga la base
      updateClientes();
      setActiveDetails({ solicitud: [{}] });
      //  Oculta los detalles
      setShowDetails(false);
    } else {
      //  Muestra aviso de error
      console.log("Error al actualizar datos");
    }
    return false;
  };

  const aplazarContacto = async (id) => {
    const result = await database.aplazarContacto(id, fechaActual());

    if (result) {
      //  Recarga la base
      updateClientes();
      setActiveDetails({ solicitud: [{}] });
      //  Oculta los detalles
      setShowDetails(false);
    } else {
      //  Muestra aviso de error
      console.log("Error al actualizar datos");
    }
  };

  const cancelar = async (id, codigo) => {
    const result = await database.cancelar(id, codigo, fechaActual());

    if (result) {
      //  Recarga la base
      updateClientes();
      setActiveDetails({ solicitud: [{}] });
      //  Oculta los detalles
      setShowDetails(false);
    } else {
      //  Muestra aviso de error
      console.log("Error al actualizar datos");
    }
  };

  const confirmarPago = async (datos) => {
    const resultado = await database.confirmarPagoGo({
      nombre: datos.nombre,
      id: datos.id,
      fecha: fechaActual(),
      folio: datos.folio,
      concepto: datos.concepto,
      monto_comisionable: datos.monto_comisionable,
      iva: datos.iva,
      monto: datos.monto,
      comision_porcentaje: datos.comision_porcentaje,
      razon: datos.razon,
      cuenta: datos.cuenta,
      marca: datos.marca,
      numero_Pago: datos.numero_Pago,
      asesor_nombre: datos.asesor_nombre,
      asesor_id: datos.asesor_id,
      comision: datos.comision,
      referencia: datos.referencia,
    });

    if (resultado.status) {
      //  Recarga la base
      //  Oculta los detalles
    } else {
      //  Muestra aviso de error
      console.log(resultado.error);
      alert(resultado.error);
    }

    setActiveDetails({ solicitud: [{}] });
    updateClientes();
    setShowDetails(false);

    return false;
  };
  const confirmarFicha = async (datos) => {
    const resultado = await database.confirmarFichaGo();

    if (resultado.status) {
      //  Recarga la base
      //  Oculta los detalles
    } else {
      //  Muestra aviso de error
      console.log(resultado.error);
      alert(resultado.error);
    }

    setActiveDetails({ solicitud: [{}] });
    updateClientes();
    setShowDetails(false);

    return false;
  };

  const generaReporte = async () => {
    const inicio = new Date("2024", "6", "1", "00", "00", "00");
    const fin = new Date("2024", "7", "31", "23", "59", "59");
    const reporte_crudo = database.getReportClents(
      inicio,
      fin,
      "5s3PdMOkhhMC32Zz4kzc0YI6gMk2"
    );
    const reporte_json = JSON.stringify(reporte_crudo);
    const bytes = new TextEncoder().encode(reporte_json);
    const blob = new Blob([bytes], {
      type: "application/json;charset=utf-8",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "Reporte.json";
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  return (
    <div className={styles.contenedor}>
      <div className={styles.saludo}>
        <h1 className={styles.cabecera} onClick={() => setShowSidemenu(true)}>
          <span className="material-symbols-outlined">menu</span>
          {titulo}
        </h1>
        <SearchBar filter={textFilter} setFilter={setTextFilter} />
      </div>

      {bases ? (
        <ChipContainerComponent
          activeChip={baseChip}
          setActiveChip={setBaseChip}
          chips={["Nueva", "Vieja", "Todo"]}
        />
      ) : (
        <></>
      )}

      <ChipContainerComponent
        chips={chips}
        activeChip={chip}
        setActiveChip={setChip}
        count={clientesFiltrados.length}
      />

      {marcas ? (
        <ChipContainerComponent
          activeChip={marcaChip}
          setActiveChip={setMarcaChip}
          chips={[
            "Todas",
            "Fortuna Capital",
            "Solucion Financiera",
            "Bien Crediticio",
            "Prestamo 24/7",
            "Prestamo 24",
          ]}
        />
      ) : null}

      {chip === "Nuevos" ||
      chip === "Adicionales" ||
      chip === "Reciclaje" ||
      chip === "Confirmar pago" ? null : (
        <ChipContainerComponent
          chips={asesores}
          activeChip={asesorChip}
          setActiveChip={setAsesorChip}
        />
      )}

      <ClientsContainer
        clientes={clientesFiltrados}
        showDetails={showDetails}
        onClientClick={(id, key = 0) => {
          onClientClick(id, key);
        }}
        rechazar={rechazar}
        admin={true}
        activeId={activeId}
        loading={database.loading}
        error={database.error}
      />

      <ClientDetails
        details={activeDetails}
        activeClient={activeClient}
        show={showDetails}
        hide={() => {
          setShowDetails(false);
          setActiveId("");
        }}
        asignarCliente={asignarCliente}
        confirmarPago={confirmarPago}
        confirmarFicha={confirmarFicha}
        agregarNota={agregarNota}
        aplazarContacto={aplazarContacto}
        cancelar={cancelar}
        retirar={retirar}
        rechazar={rechazar}
        admin={true}
      />

      <SideMenuComponent show={showSidemenu} setShow={setShowSidemenu} />

      <div className={styles.extraButtons}>
        <button onClick={generaReporte} className={styles.addButton}>
          <span className="material-symbols-outlined">add</span>
        </button>
      </div>
    </div>
  );
};

export default AdminComponent;
