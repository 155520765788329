import { useState } from "react";
import { closeSession } from "../../extras/firebase";
import AsistenciaButton from "../AsistenciaButton/AsistenciaButton";
import styles from "./SideMenuComponent.module.scss";

const SideMenuComponent = ({ show, setShow, asistencia, registro }) => {
  const [alert, setAlert] = useState("gfjhf");
  const [showAlert, setShowAlert] = useState(false);
  return (
    <div
      className={`${styles.container} ${show ? styles.show : styles.hide}`}
      onClick={() => setShow(false)}
    >
      <div className={styles.cuerpo} onClick={(e) => e.stopPropagation()}>
        <div>
          <div className={styles.cabecera}>
            <h1>Hola</h1>
            <button
              onClick={() => {
                setShow(false);
              }}
              className={styles.closeBttn}
            >
              <span className="material-symbols-outlined">close</span>
            </button>
          </div>
        </div>

        {/* <div>
          <AsistenciaButton
            asistencia={asistencia}
            registro={registro}
            alerta={setAlert}
          />
          <alert>{alert}</alert>
        </div> */}

        <div className={styles.pie}>
          <button onClick={() => closeSession()}>Cerrar sesión</button>
        </div>
      </div>
    </div>
  );
};

export default SideMenuComponent;
