const lat = 19.367803;
const lon = -99.1483042;

function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return d;
}

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

const AsistenciaButton = ({ asistencia, registro, alerta }) => {
  const handleAsistencia = (e) => {
    e.stopPropagation();
    e.preventDefault();

    // Obtiene la ubicacion
    navigator.geolocation.getCurrentPosition((position) => {
      const distancia = getDistanceFromLatLonInKm(
        position.coords.latitude,
        position.coords.longitude,
        lat,
        lon
      );

      alert('Seeee')
      console.log(distancia <= 0.1 ? true : false);
    });
  };

  return navigator.geolocation ? (
    <>
      <button onClick={handleAsistencia}>Registar asistencia</button>
    </>
  ) : (
    <></>
  );
};

export default AsistenciaButton;
